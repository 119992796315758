import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Deploy Secrets Manager Azure"
const pageTitle = "Deploy Secrets Manager Azure"
const subTitle = "Cloud Infrastructure"
const para =" Redesign monolith Vault deployment into TF Module.Deployment of Vault to AKS clusters.Addition of auto unseal using Azure key/vault.Enabling Performance Replication from on-premise to Azure.   Migration from Gitlab to ADO including all pipelines, repo's and logic for deployment Hashi Vault helm chart,Enterprise vault,Terraform Kubernetes module deployment,Deployment repository    Guarantee backward compatibility with legacy Vault deployment to TKGI on-premise One click upgrade of Vault image for day 2 operations Intergration with Snowflake as first dynamic secret engine."
const blockquote = `"Vault Enterprise migration to ADO \AZURE
"`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
        <p>
        The client needed Vault migrated
to Azure along with all pipelines and
code migrated to ADO from Gitlab.     
        </p>
        <li>
          {/* <Icon.Square /> */}
      
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `The Approach`,
    listItems: (
      <>
        <p>
        Deploy Vault Enterprise Clusters as
Performance Replicas in US and EU
regions of Azure from ADO.
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: `The Process`,
    listItems: (
      <>
        <p>
        Create new ADO pipelines and refactor code from Gitlab adding feature
          flags for backwards compatibility to on-premise Vault Enterprise
          Cluster deployments
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: `Result`,
    listItems: (
      <>
        <p>
          Successfully deployed preprod and prod performance replica Vault
          Enterprise clusters in Azure from ADO pipelines along with redeploying
          on-premise from ADO as well. 
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query BlackRockQuery {
    casedetailsJson(slug: { eq: "deploy-secrets-manager-azure" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
